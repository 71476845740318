import type {AuthorizeState, AuthorizeStateAction} from './types';

export function reducer(state: AuthorizeState, action: AuthorizeStateAction) {
  switch (action.type) {
    case 'setAutoOpened':
      return {...state, autoOpened: action.payload};
    case 'setLoaded':
      return {...state, loaded: action.payload};
    case 'setModalAnimating':
      return {...state, modalAnimating: action.payload};
    case 'setModalVisible':
      return {...state, modalVisible: action.payload};
    case 'setSessionDetected':
      return {...state, sessionDetected: action.payload};
    case 'updateState':
      return {...state, ...action.payload};
    default:
      return state;
  }
}
