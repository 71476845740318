import {useRef, useCallback} from 'preact/hooks';

/**
 * Debounces a function call. Stores the timer in a ref to preserve it across re-renders.
 */
export function useDebouncedCallback<T extends any[]>(
  callback: (...args: T) => any,
  delay = 200,
) {
  const timerRef = useRef<ReturnType<typeof setTimeout>>();
  const callbackRef = useRef(callback);
  callbackRef.current = callback;

  return useCallback(
    (...args: T) => {
      if (timerRef.current != null) {
        clearTimeout(timerRef.current);
      }

      timerRef.current = setTimeout(
        (...args) => callbackRef.current?.(...args),
        delay,
        ...args,
      );
    },
    [delay],
  );
}
