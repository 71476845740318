import {v4 as uuid} from 'uuid';

import {PAY_AUTH_DOMAIN} from '~/constants/authorize';
import type {Bugsnag} from '~/foundation/Bugsnag/Bugsnag';
import type {Monorail} from '~/foundation/Monorail/Monorail';
import {isoNavigator} from '~/utils/navigator';
import {isoWindow} from '~/utils/window';

interface InitFedCMParams {
  mediation?: CredentialMediationRequirement;
  bugsnag?: Bugsnag;
  monorailTracker?: Monorail;
}

export const initFedCM = async (params: InitFedCMParams) => {
  const {mediation = 'optional', bugsnag, monorailTracker} = params;

  return new Promise((resolve: (res: Response) => void, reject) => {
    try {
      // Browser does not support FedCM
      if (!('IdentityCredential' in isoWindow)) {
        return;
      }

      monorailTracker?.trackPageImpression({
        page: 'FEDCM_SHOWN',
      });

      // Make the request for credentials
      getFedCMCredentials(mediation)
        .then((credential) => {
          // User has cancelled the fedCM prompt
          if (!credential) {
            monorailTracker?.trackUserAction({userAction: 'FEDCM_CANCELLED'});
            return;
          }
          // Open session on merchant.com
          submitTokenToServer(
            (credential as IdentityCredential).token,
            resolve,
            reject,
            monorailTracker,
          );
        })
        .catch((error) => {
          reject(error);
        });
    } catch (error: any) {
      bugsnag?.notify(error);

      reject(error);
    }
  });
};

function getFedCMCredentials(mediation: CredentialMediationRequirement) {
  return isoNavigator.credentials.get({
    identity: {
      providers: [
        {
          configURL: `${PAY_AUTH_DOMAIN}/pay/fedcm/config.json`,
          clientId: isoWindow.Shopify.shop,
          nonce: uuid(),
        },
      ],
    },
    mediation,
  });
}

function submitTokenToServer(
  token: string,
  resolve: (value: Response) => void,
  reject: (error?: any) => void,
  monorailTracker?: Monorail,
) {
  fetch('/services/login_with_shop/fedcm/callback', {
    method: 'POST',
    headers: {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    body: new URLSearchParams({
      // eslint-disable-next-line @typescript-eslint/naming-convention
      raw_id_token: token,
    }).toString(),
  })
    .then((response) => {
      monorailTracker?.trackUserAction({userAction: 'FEDCM_COMPLETED'});
      resolve(response);
    })
    .catch((error) => reject(error));
}
