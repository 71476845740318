export function debounce<TParams extends any[]>(
  originalFunction: (...params: TParams) => void,
  delay: number,
): (...args: TParams) => void {
  let timer: ReturnType<typeof setTimeout>;
  return function (this: any, ...args: TParams) {
    clearTimeout(timer);
    timer = setTimeout(() => originalFunction.apply(this, args), delay);
  };
}
