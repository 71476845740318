import {isoNavigator} from './navigator';
import {isoWindow} from './window';

export function getQueryParam(name: string): string | null {
  const urlParams = new URLSearchParams(isoWindow.location.search);
  return urlParams.get(name);
}

export function isMobileBrowser(): boolean {
  return (
    Boolean(isoNavigator.userAgent) &&
    /(android|iphone|ipad|mobile|phone)/i.test(isoNavigator.userAgent)
  );
}

export function removeTrailingSlash(value: string): string {
  if (value === '/') return value;
  return value.endsWith('/') ? value.slice(0, -1) : value;
}

export function isIosSafari() {
  const agent = isoNavigator.userAgent;
  const iOS = Boolean(agent.match(/iPad/i)) || Boolean(agent.match(/iPhone/i));
  const webkit = Boolean(agent.match(/WebKit/i));
  return iOS && webkit && !agent.match(/CriOS/i);
}
