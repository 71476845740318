import {useEffect} from 'preact/hooks';

import {useRootProvider} from '~/foundation/RootProvider/hooks';

export interface InferredEventListeners<T> {
  [key: string]: T extends Record<keyof T, infer TEvent>
    ? (event: TEvent) => void | PromiseLike<void>
    : never;
}

export function useElementEventListener<T = Record<string, CustomEvent>>(
  listeners: InferredEventListeners<T>,
) {
  const {element} = useRootProvider();

  useEffect(() => {
    if (!element) {
      return;
    }

    Object.entries(listeners).forEach(([event, handler]) => {
      element.addEventListener(event, handler);
    });

    return () => {
      Object.entries(listeners).forEach(([event, handler]) => {
        element?.removeEventListener(event, handler);
      });
    };
  }, [element, listeners]);
}
