interface CredentialOptions {
  password?: boolean;
  federated?: boolean;
  publicKey?: boolean;
}

interface PasswordCredential {
  id: string;
  password: string;
}

interface FederatedCredential {
  id: string;
  provider: string;
  token: string;
}

type Credential = PasswordCredential | FederatedCredential;

interface Credentials {
  get(options: CredentialOptions): Promise<Credential | null>;
}
const navigatorWithDefaultValues = {
  languages: [],
  userAgent: '',
  userLanguage: '',
  credentials: {} as Credentials,
};

export const isoNavigator =
  typeof navigator === 'undefined' ? navigatorWithDefaultValues : navigator;
